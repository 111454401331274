export default [
  {
    path: "/feed-back/modify/:id",
    name: "feed-back-modify",
    component: () => import("@/views/feed_back/Modify.vue"),
    meta: {
      layout: "default",
      category: "反馈管理",
      title: "修改反馈",
    }
  },
  {
    path: "/feed-back/list",
    name: "feed-back-list",
    component: () => import("@/views/feed_back/List.vue"),
    meta: {
      layout: "default",
      category: "反馈管理",
      title: "反馈列表",
    }
  }
]
