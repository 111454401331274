export default [
  {
    icon: "mdi-clock-time-three-outline",
    key: "menu.articles",
    text: "快速上手",
    link: "/article/quick-start"
  },
  {
    icon: "mdi-school",
    key: "menu.articles",
    text: "进阶技巧",
    link: "/article/docs"
  },
  {
    icon: "mdi-book-outline",
    key: "menu.articles",
    text: "纯血鸿蒙组件库",
    link: "/article/aui"
  },
  {
    icon: "mdi-message-outline",
    key: "menu.feedback",
    text: "用户反馈",
    link: "/feed-back/list"
  },
  {
    icon: "mdi-math-log",
    key: "menu.logs",
    text: "更新日志",
    link: "/update-log/list"
  }
]
