<!--深色模式/浅色模式切换-->
<script setup lang="ts">
import { onMounted, computed } from "vue"
import { Icon } from "@iconify/vue"
import { useTheme } from "vuetify"
import { useCustomizeThemeStore } from "@/stores/customizeThemeStore"
const customizeTheme = useCustomizeThemeStore()
const theme = useTheme()

// 页面加载时初始化主题
onMounted(() => {
  customizeTheme.initializeTheme()
})

const toggleTheme = () => {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark"
  customizeTheme.setTheme(theme.global.name.value)
}

const isDark = computed(() => theme.global.current.value.dark)
</script>

<template>
  <v-btn v-if="isDark" @click="toggleTheme" icon>
    <Icon width="30" icon="line-md:moon-filled-loop" />
  </v-btn>

  <v-btn v-else @click="toggleTheme" icon color="white" class="text-red">
    <Icon
      width="30"
      icon="line-md:moon-filled-alt-to-sunny-filled-loop-transition"
    />
  </v-btn>
</template>

<style scoped lang="scss"></style>
