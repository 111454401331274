<template>
  <div class="d-flex text-center flex-grow-1">
    <v-sheet
      class="layout-side d-none d-md-flex flex-column justify-space-between"
    >
      <div class="mt-3 mt-md-10 pa-2">
        <v-btn class="mt-16 mb-8" size="120" color="white">
          <img src="@/assets/logo.png" width="100" alt="AI极客"/>
        </v-btn>
        <div class="text-primary text-h4 font-weight-bold">
          AI极客管理后台
        </div>
        <div class="title my-2">
          AIGC低代码平台
        </div>
      </div>
    </v-sheet>
    <v-card
      variant="outlined"
      class="flex-grow-1 d-flex align-center justify-center flex-column"
    >
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <div class="pa-5">AIGCoder.com 2024 © All Rights Reserved</div>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
