export default [
  {
    icon: "mdi-newspaper-variant-outline",
    key: "menu.fastapi",
    text: "AI资讯",
    link: "/coder/fastapi",
  },
  {
    icon: "mdi-newspaper-variant-outline",
    key: "menu.fastapi",
    text: "模型管理",
    link: "/coder/fastapi",
  },
]
