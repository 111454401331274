export default [
  {
    path: "/user/list",
    name: "user-list",
    component: () => import("@/views/user/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "用户",
      title: "用户列表",
    }
  },
  {
    path: "/user/detail/:id",
    name: "user-detail",
    component: () => import("@/views/user/Detail.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "用户",
      title: "用户详情",
    }
  },
]
