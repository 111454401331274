import { createRouter, createWebHistory } from "vue-router"
import AuthRoutes from "./auth.routes"
import OrderRoutes from "./order.routes"
import ArticleRoutes from "./article.routes"
import FeedBackRoutes from "./feed_back.routes"
import UpdateLogRoutes from "./update_log.routes"
import UserRoutes from "./user.routes"
import ComponentRoutes from "./component.routes"

export const routes = [
  {
    path: "/",
    redirect: "/auth/login"
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/errors/NotFoundPage.vue"),
  },
  ...AuthRoutes,
  ...OrderRoutes,
  ...ArticleRoutes,
  ...FeedBackRoutes,
  ...UpdateLogRoutes,
  ...UserRoutes,
  ...ComponentRoutes
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = []

const router = createRouter({
  history: createWebHistory(),
  // hash模式：createWebHashHistory，history模式：createWebHistory
  // process.env.NODE_ENV === "production"

  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
