<script setup lang="ts">
import router from "@/router"
import { AuthStore } from "@/stores/AuthStore"

const authStore = AuthStore()

const handleLogout = async () => {
  await router.push("/auth/logout")
}

</script>

<template>
  <v-btn v-if="!authStore.isLoggedIn" class="mx-2" icon to="/auth/login">
    <v-avatar size="40" class="custom-avatar">
      登录
    </v-avatar>
  </v-btn>
  <v-btn v-if="!authStore.isLoggedIn" class="mx-2" icon to="/auth/signup">
    <v-avatar size="40" class="custom-avatar">
      注册
    </v-avatar>
  </v-btn>
  <v-btn v-if="authStore.isLoggedIn" class="mx-2" icon @click="handleLogout">
    <v-avatar size="40" class="custom-avatar">
      <v-icon>mdi-logout</v-icon>
    </v-avatar>
  </v-btn>
</template>

<style scoped lang="scss">
.custom-avatar {
  border: none; /* 移除边框 */
}
</style>
