export default [
  {
    path: "/article/create/:id",
    name: "article-create",
    component: () => import("@/views/article/Create.vue"),
    meta: {
      layout: "default",
      category: "文章管理",
      title: "新增文章",
    }
  },
  {
    path: "/article/modify/:id",
    name: "article-modify",
    component: () => import("@/views/article/Modify.vue"),
    meta: {
      layout: "default",
      category: "文章管理",
      title: "修改文章",
    }
  },
  {
    path: "/article/quick-start",
    name: "article-quick-start",
    component: () => import("@/views/article/QuickStartList.vue"),
    meta: {
      layout: "default",
      category: "文章管理",
      title: "快速上手",
    }
  },
  {
    path: "/article/docs",
    name: "article-docs",
    component: () => import("@/views/article/DocsList.vue"),
    meta: {
      layout: "default",
      category: "文章管理",
      title: "进阶技巧",
    }
  },
  {
    path: "/article/aui",
    name: "article-aui",
    component: () => import("@/views/article/AuiList.vue"),
    meta: {
      layout: "default",
      category: "文章管理",
      title: "纯血鸿蒙组件库",
    }
  }
]
