// 登录状态、令牌信息及用户信息
import { defineStore } from "pinia";

// 令牌信息
interface TokenInfo {
  token: string
  tokenTimeout: bigint
  refreshToken: string
  refreshTokenTimeout: bigint
}

// 用户信息
interface UserInfo {
  userId: bigint
  username: string
  nickname: string
  avatar: string
}

export const AuthStore = defineStore({
  id: "auth",
  state: () => ({
    isLoggedIn: false,
    tokenInfo: null as TokenInfo | null,
    userInfo: null as UserInfo | null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["isLoggedIn", "tokenInfo", "userInfo"]
      }
    ]
  },
  actions: {
    // 设置登录状态
    setLoggedIn(payload: boolean) {
      this.isLoggedIn = payload;
    },
    // 读取用户信息
    getUserInfo() {
      return {
        userId: this.userInfo.userId,
        username: this.userInfo.username,
        nickname: this.userInfo.nickname,
        avatar: this.userInfo.avatar
      }
    },
    // 设置用户信息
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo
    },
    // 读取令牌信息
    getTokenInfo() {
      return {
        token: this.tokenInfo.token,
        tokenTimeout: this.tokenInfo.tokenTimeout,
        refreshToken: this.tokenInfo.refreshToken,
        refreshTokenTimeout: this.tokenInfo.refreshTokenTimeout
      }
    },
    // 设置令牌信息
    setTokenInfo(tokenInfo: TokenInfo) {
      this.tokenInfo = tokenInfo
    },
    // 注销退出
    clearAuthInfo() {
      this.isLoggedIn = false
      this.tokenInfo = null
      this.userInfo = null
    }
  }
})
