export default [
  {
    path: "/auth/login",
    name: "auth-login",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/auth/Login.vue"),
    meta: {
      layout: "auth",
      title: "登录",
    }
  },
  {
    path: "/auth/logout",
    name: "auth-logout",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/auth/Logout.vue"),
    meta: {
      layout: "auth",
      title: "注销退出",
    }
  }
]
