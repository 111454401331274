export default [
  {
    icon: "mdi-cash-100",
    key: "menu.order",
    text: "订单管理",
    link: "/order/list",
  },
  {
    icon: "mdi-account-multiple",
    key: "menu.user",
    text: "用户管理",
    link: "/user/list",
  },
]
