export default [
  {
    path: "/component/list",
    name: "component-list",
    component: () => import("@/views/component/List.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "组件管理",
      title: "组件列表",
    }
  },
  {
    path: "/component/model/:id",
    name: "component-model",
    component: () => import("@/views/component/Model.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "组件管理",
      title: "组件Model",
    }
  },
  {
    path: "/component/config/:id",
    name: "component-config",
    component: () => import("@/views/component/Config.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
      category: "组件管理",
      title: "配置属性",
    }
  }
]
