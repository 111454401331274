export default [
  {
    path: "/update-log/create",
    name: "update-log-create",
    component: () => import("@/views/update_log/Create.vue"),
    meta: {
      layout: "default",
      category: "升级日志管理",
      title: "新增升级日志",
    }
  },
  {
    path: "/update-log/modify/:id",
    name: "update-log-modify",
    component: () => import("@/views/update_log/Modify.vue"),
    meta: {
      layout: "default",
      category: "升级日志管理",
      title: "修改升级日志",
    }
  },
  {
    path: "/update-log/list",
    name: "update-log-list",
    component: () => import("@/views/update_log/List.vue"),
    meta: {
      layout: "default",
      category: "升级日志管理",
      title: "升级日志列表",
    }
  }
]
