import menuOperation from "./menus/operation.menu"
import cmsOperation from "./menus/cms.menu"
import menuAigc from "./menus/aigc.menu"
import menuComponent from "./menus/component.menu"

export default {
  menu: [
    {
      text: "运营中心",
      items: menuOperation,
    },
    {
      text: "内容管理",
      items: cmsOperation,
    },
    {
      text: "AIGC管理",
      items: menuAigc,
    },
    {
      text: "基础数据",
      items: menuComponent,
    }
  ]
}
